import React from 'react';
import './Jobs.css';
import { jobs } from './jobsDesc';
import Table from './Table';
import { useState } from 'react';

const Jobs = () => {
  const [query ,setQuery]=useState("");
  const keys = ["role","location","team"];

  const search = (data)=>{
    return data.filter(
      (item)=>keys.some((key)=>item[key].toLowerCase().includes(query))
    );
  }



  return (
    <div className='job-main'>
      <div className='search-div'>
        <input className='search-tab' type='search' placeholder='Search for a job' onChange={(e)=>setQuery(e.target.value)} />
      </div>
      <div className='table-container'>
        <Table className="table" data={search(jobs)}/>
      </div>

    </div>
  )
}

export default Jobs;