import React, { useEffect, useState, useCallback } from "react";
import Questions from "./Questions";
import Timer from "./Timer";
import "../styles/AptitudeApp.css";
import "../styles/quiz.css";
import Modal from "react-modal";

import { MoveNextQuestion, MovePrevQuestion } from "../hooks/FetchQuestion";
import { PushAnswer } from "../hooks/setResult";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import left from "../assets/arrow-left.png";
import right from "../assets/arrow-right.png";
import Result from "./Result";
Modal.setAppElement("#root");
export default function Quiz() {
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
  };

  const handleTimeout = () => {
    if (!quizSubmitted) {
      setQuizSubmitted(true);
      navigate("/result");
      
    }
    // return <Navigate to={'/result'} replace={true}></Navigate>
    // setQuizSubmitted(true);
    // console.log('Quiz is submitted');
  };

  const handleSubmit = () => {
    if (!quizSubmitted) {
      setQuizSubmitted(true);
      if (result.length <= trace) {
        dispatch(PushAnswer(check));
      }

      navigate("/result");
      
    }
  };

  // function onTimeout()
  // {
  //     <Navigate to={'/result'} replace={true}></Navigate>
  // }
  const [check, setChecked] = useState(undefined);

  const result = useSelector((state) => state.result.result);
  const { queue, trace } = useSelector((state) => state.questions);
  const dispatch = useDispatch();

  const handleNextDebounced = debounce(() => {
    onNext();
  }, 800); // Adjust the delay time as needed

  const handlePrevDebounced = debounce(() => {
    onPrev();
  }, 800);

  const onNext = useCallback(() => {
    if (trace < queue.length) {
      dispatch(MoveNextQuestion());
      if (result.length <= trace) {
        dispatch(PushAnswer(check));
      }
    }
    setChecked(undefined);
  }, [dispatch, trace, queue, result, check]);

  const onPrev = useCallback(() => {
    if (trace > 0) {
      dispatch(MovePrevQuestion());
    }
  }, [dispatch, trace]);

  function onChecked(check) {
    setChecked(check);
  }

  /** finished exam after the last question */
  if (result.length && result.length >= queue.length) {
    return <Navigate to={"/result"} replace={true}></Navigate>;
  }

  // function onTimeout()
  // {
  //     return <Navigate to={'/result'} replace={true}></Navigate>
  // }

  return (
    <div className="parent-container">
      <div className="logo">
        <div>
          <h1 className="quiz-text-light">INCRONIX APTITUDE</h1>
        </div>
      </div>
      <div className="main-container">
        <div className="timer_qno">
          <Timer
            duration={2400}
            onTimeout={handleTimeout}

            //   onTimeout={ <Navigate to={'/result'} replace={true}></Navigate>}
          />
        </div>
        <div className="questions-container">
          {!quizSubmitted ? (
            <>
              <Questions onChecked={onChecked} />

              {/* <div className={`grid ${isModalOpen ? "blur" : ""}`}> */}
              <div className="grid">
                {trace > 0 ? (
                  <button className="prev" onClick={handlePrevDebounced}>
                    <img src={left} className="left" alt="" />
                  </button>
                ) : (
                  <div></div>
                )}
                {trace < queue.length - 1 ? (
                  <button className="next" onClick={handleNextDebounced}>
                    <img src={right} className="right" alt="" />
                  </button>
                ) : (
                  <div></div>
                )}
                {/* <button className="next" onClick={handleNextDebounced}>
              Next
            </button> */}
                {trace < queue.length - 1 ? (
                  <button className="submitbtn" onClick={handleOpenModal}>
                    END TEST
                  </button>
                ) : trace === queue.length - 1 ? (
                  <button className="submitbtn" onClick={handleOpenModal}>
                    END TEST
                  </button>
                ) : (
                  <div></div>
                )}
                {/* <button className="submitbtn" onClick={handleOpenModal}>
                END TEST
              </button> */}
              </div>

              <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                contentLabel="Custom Warning Modal"
                className="modal-container"
                overlayClassName="overlay"
                shouldCloseOnOverlayClick={false}
              >
                <div>
                  <p>Are you sure you want to submit?</p>
                  <button onClick={handleSubmit}>Yes</button>
                  <button onClick={handleCloseModal}>No</button>
                </div>
              </Modal>
            </>
          ) : (
            <p>{Result}</p>
          )}
        </div>
        <div className="remaing-questions">
          <p className="text-light">
            Question {trace + 1} of {queue.length}
          </p>
        </div>
      </div>
    </div>
  );
}
