import React from "react";
import "./Card.css";


function Card({ title, imgUrl, subTitle }) {
  return (
    <div className="card-page-div">
      {/* {data.map((item)=>( */}
      <div className="card-container">
        {" "}
        {/*key={item.id} }*/}
        <div className="card-img-container">
          <img src={imgUrl} alt="img" />
        </div>
        <div className="card-content">
          <div className="card-title">
            <h3>{title}</h3>
            <hr></hr>
          </div>
          <div className="card-sub-title">
            <h2>{subTitle}</h2>
          </div>
          <div className="card-body">
            {/* <ul className='unordered-list'>
                    <li className='list-item'>{item.para1}</li>
                    <li className='list-item'>{item.para2}</li>
                    <li className='list-item'>{item.para3}</li>
                </ul> */}
          </div>
          {/* <div className='card-btn'>
            <button>
                <p>Read more</p>
            </button>
        </div> */}
        </div>
      </div>
      {/*))}*/}
    </div>
  );
}

export default Card;
