import React from "react";
import "./Footer.css";
import cLogo from "../assets/logo.webp";
import nameLogo from "../assets/incronix_cropped.webp";
import fullLogo from "../assets/incronix-01.webp";

const Footer = () => {
  let year = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="footerMain section-padding">
        <div className="footer-links">
          <div className="footer-logo">
            {/* <h1>INCRONIX</h1> */}
            <img
              className="footer-logo-img"
              src={fullLogo}
              alt="footer logo"
              loading="lazy"
            />
          </div>

          <hr></hr>

          <div className="footer-below">
            <div className="footer-copyright">
              <p>© {year} INCRONIX TECHNOLOGY, All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
