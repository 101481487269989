import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../styles/AptitudeApp.css";
import { debounce } from "lodash";
import { useFetchQestion } from "../hooks/FetchQuestion";
import { updateResult } from "../hooks/setResult";
import Loader from "./Loader";

export default function Questions({ onChecked}) {
  const [checked, setChecked] = useState(undefined);
  const { trace } = useSelector((state) => state.questions);
  const result = useSelector((state) => state.result.result);
  const [{ isLoading, apiData, serverError }] = useFetchQestion();

  const questions = useSelector(
    (state) => state.questions.queue[state.questions.trace]
  );
  const totalQuestions = { trace }.length || 0;
  const dispatch = useDispatch();

  const onSelectDebounced = debounce((i) => {
    onSelect(i);
  }, 800);

  useEffect(() => {
    dispatch(updateResult({ trace, checked }));
  }, [checked]);

  function onSelect(i) {
    onChecked(i);
    setChecked(i);
    dispatch(updateResult({ trace, checked }));
  }

  if (isLoading)
    return (
      <h3 className="text-light">
        <Loader />
      </h3>
    );
  if (serverError)
    return <h3 className="text-light">{serverError || "Unknown Error"}</h3>;

  return (
    <div className="questions">
      <p className="text-light">
        {trace + 1}. {questions?.question}
      </p>

      <ul className="option-list" key={questions?.id}>
        {questions?.options.map((q, i) => (
          <li className="all-options" key={i}>
            <input
              className="input_tag"
              type="radio"
              value={false}
              name="options"
              id={`q${i}-option`}
              onChange={() => onSelectDebounced(i)}
            />

            <label className="text-primary" htmlFor={`q${i}-option`}>
              {q}
            </label>
            <div
              className={`check ${result[trace] == i ? "checked" : ""}`}
            ></div>
          </li>
        ))}
      </ul>
      
    </div>
  );
}
