import React from "react";
import "./About.css";
import AboutAnimation from "../assets/about-us.json";
import Lottie from "lottie-react";

function About() {
  const aboutHeaderText = "About INCRONIX";
  const aboutPara =
    "At INCRONIX, we are driven by a passion for technology and a commitment to delivering innovative software solutions that empower businesses to thrive in the digital age. Our passionate team of developers, designers, and tech wizards brings your vision to life. We're here to bridge the gap between your ideas and their real-world impact, helping you conquer your goals.";
  return (
    <div className="about-main-div">
      <section className="about-header-section">
        <div className="all-text">
          <h1 className="about-header-text">{aboutHeaderText}</h1>
          <p className="about-para">{aboutPara}</p>
        </div>
        <div className="image">
          <Lottie animationData={AboutAnimation} />
        </div>
      </section>
    </div>
  );
}
export default About;
