import React, { Suspense } from "react";
import Lottie from "lottie-react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import "./homepage.css";
import whywe from "../assets/for-why-we.json";
import whywe2 from "../assets/for-why-we-2.json";
import fullLogo from "../assets/incronix-01.webp";
import Loader from "./loader";

const LazyLottie1 = React.lazy(() => import("./LazyLottie1"));
const LazyLottie2 = React.lazy(() => import("./LazyLottie2"));

function Home() {
  const incronix = "INCRONIX";
  const heroParagraph =
    "Pioneering technology meets unmatched flexibility, delivering innovative answers for businesses across the globe.";
  const whyweParagraph =
    "INCRONIX is a dynamic force in the technology arena, relentlessly shaping the landscape of innovation. Our solutions equip businesses and individuals with the tools they need to not only survive, but flourish, in any situation. We're relentless in our pursuit of groundbreaking ideas, constantly pushing the limits of what's possible. By providing a strong and adaptable base, we empower our clients to navigate change with certainty and capitalize on emerging opportunities.";
  const heroSubHeading = "Shaping the Landscape of Innovation";
  const [text] = useTypewriter({
    words: [" ELEVATE", " INNOVATE", " IGNITE"],
    loop: {},
    typeSpeed: 120,
    deleteSpeed: 80,
  });
  const slogan = "INNOVATION SPARKS ,INCRONIX :";
  return (
    <div className="home-main-div">
      {/* <div className="forBackgroundHeroAndSlogan"> */}
      <section className="hero-section">
        <div className="homepagetxt">
          {/* <h1 className="hero-heading"> 
                {incronix}
            </h1> */}
          <img src={fullLogo} alt="" className="fullLogo" />
          <h2 className="hero-sub-heading">{heroSubHeading}</h2>
          <p className="hero-para">{heroParagraph}</p>
        </div>
        <div className="laptopimg">
          {/* <Lottie className="lottie-animation" animationData={whywe} /> */}
          {/* Suspense to handle lazy loading */}
          <Suspense fallback={<Loader />}>
            <LazyLottie1 />
          </Suspense>
        </div>
      </section>
      <div className="slogan-container">
        <p className="slogan">
          {slogan}
          <span style={{ color: "green", fontWeight: "600" }}>{text}</span>
          <Cursor cursorStyle="/" color="green" />
        </p>
      </div>
      {/* </div> */}

      <section className="why-we-section">
        <div className="why-we-text-div">
          <h2 className="why-we-main-text">Why we?</h2>
          <p className="why-we-para">{whyweParagraph}</p>
        </div>

        <div className="why-we-img">
          {/* <Lottie className="lottie-animation" animationData={whywe2} /> */}
          {/* Suspense to handle lazy loading */}
          <Suspense fallback={<Loader />}>
            <LazyLottie2 />
          </Suspense>
        </div>
      </section>
    </div>
  );
}
export default Home;
