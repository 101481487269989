import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import axios from "axios";

export function attempts_Number(result) {
  return result.filter((r) => r !== undefined).length;
}

// export function earnPoints_Number(result, answers, point){
//     return result.map((element, i) => answers[i] === element).filter(i => i).map(i => point).reduce((prev, curr) => prev + curr, 0);
// }

// export function earnPoints_Number(result, answers, point) {
//     const correctAnswers = result.filter((element, i) => answers[i] === element);
//     const totalPoints = correctAnswers.length * point;
//     return totalPoints;
//   }

// export function earnPoints_Number(result, answers, point) {
//   // Map selected answers to an array of boolean values indicating correctness
//   const correctnessArray = result.map((selectedAnswer, i) => {
//     const correctAnswer = answers.find((answer) => answer.id === i + 1); // Assuming 1-based indexing
//     return correctAnswer ? selectedAnswer === correctAnswer.answer : false;
//   });

//   // Count the correct answers and calculate the total points
//   const correctAnswersCount = correctnessArray.filter(
//     (correct) => correct
//   ).length;
//   const totalPoints = correctAnswersCount * point;

//   return totalPoints;
// }

export function earnPoints_Number(queue, result, answers, point) {
  // Map selected indices to an array of boolean values indicating correctness
  const correctnessArray = result.map((selectedIndex, i) => {
    
    const correctAnswerIndex = answers[i].answer; // Assuming answers[i] has the structure { id: questionId, answer: correctIndex }

    

    // Compare the selected index to the correct answer index
    return selectedIndex === correctAnswerIndex;
  });



  // Count the correct answers and calculate the total points
  const correctAnswersCount = correctnessArray.filter(
    (correct) => correct
  ).length;
  const totalPoints = correctAnswersCount * point;

  // Debugging: Log the result and total points


  return totalPoints;
}

export function flagResult(totalPoints, earnPoints) {
  return (totalPoints * 50) / 100 < earnPoints;
}

export function CheckUserExist({ children }) {
  const auth = useSelector((state) => state.result.userId);
  return auth ? children : <Navigate to={"/"} replace={true}></Navigate>;
}

export async function getServerData(url, callback) {
  const data = await (await axios.get(url))?.data;
  return callback ? callback(data) : data;
}

export async function postServerData(url, result, callback) {
  const data = await (await axios.post(url, result))?.data;
  return callback ? callback(data) : data;
}
