// client/src/components/Main.js
import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startExamAction } from "../redux/question_reducer";
import { setUserId } from "../redux/result_reducer";
import "../styles/Main.css";
import Footer from "../../landing page module/Footer";
import Modal from "react-modal";
Modal.setAppElement("#root");

const Main = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
  };
  const handleStartQuiz = () => {
    if (isChecked) {
      // Perform form submission or any other action

      startQuiz();
    } else {
      alert("Click terms and conditions");
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // const handleSubmit = () => {

  // };

  useEffect(() => {
    const storedState = localStorage.getItem("state");
    if (storedState) {
      const { result, questions } = JSON.parse(storedState);
      dispatch(
        startExamAction({
          question: questions.queue,
          answers: questions.answers,
        })
      );
      dispatch(setUserId(result.userId));
      navigate("/quiz");
    }
  }, [dispatch, navigate]);

  const startQuiz = async () => {
    // Perform authentication logic here
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_HOSTNAME}/api/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, password }),
        }
      );

      const data = await response.json();

      if (data.success) {
        // If authentication is successful, set the user ID in Redux state
        dispatch(setUserId(inputRef.current?.value));
        navigate("/quiz");
      } else {
        // If authentication fails, display an error message
        alert("Invalid credentials");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };
  let year = new Date().getFullYear();

  return (
    <div className="login-page-main-container">
      <h1 className="title text-light">INCRONIX APTITUDE</h1>
      <div className="instructions-login-container">
        <div className="instructions-div">
          <div className="instructions-heading">
            Software Developer Internship Program for Jan to June 2024 Aptitude
            Test<br></br>
            <br></br>
            Instructions:<br></br>
          </div>
          <ol className="aptitude-instruction-list">
            <li className="aptitude-instruction-item">
              Time duration of 40 minutes for 60 questions
            </li>
            <li className="aptitude-instruction-item">
              Use laptop/desktop only; mobile devices not allowed
            </li>
            <li className="aptitude-instruction-item">
              Avoid interruptions; test may be recovered if restarting from same
              device and browser
            </li>
            <li className="aptitude-instruction-item">
              During the test, refrain from switching tabs; maintain focus for a
              smooth experience
            </li>
          </ol>
        </div>

        {/* Login Form */}
        <form className="loginForm" id="form">
          <label className="form_labels">Username</label>
          <input
            ref={inputRef}
            className="userid"
            type="text"
            placeholder="Username*"
            value={username}
            name="username"
            required
            autoComplete="off"
            onChange={(e) => setUsername(e.target.value)}
          />
          <label className="form_labels">Password</label>
          <input
            className="userid"
            type="password"
            placeholder="Password*"
            required
            value={password}
            name="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <label>
            <input
              className="checkbox-label"
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            I agree to the terms and conditions
          </label>
          <div className="start">
            {/* Start Quiz Button with Authentication Logic */}
            <button
              className="bttn"
              onClick={handleOpenModal}
              disabled={!isChecked}
            >
              Start Quiz
            </button>
          </div>
        </form>
      </div>
      <Footer />

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Custom Warning Modal"
        className="quiz-modal-container"
        overlayClassName="overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div>
          <p>Are you sure you want to exam</p>
          <button onClick={handleStartQuiz}>Yes</button>
          <button onClick={handleCloseModal}>No</button>
        </div>
      </Modal>
    </div>
  );
};

export default Main;
