export const jobs=[
    {
        "id":1,
        "role":"Software Intern",
        "location":"Pune",
        "team":"Software Development"
    },
    {
        "id":2,
        "role":"Software Intern",
        "location":"Remote",
        "team":"Software Development"
    },
    {
        "id":3,
        "role":"Software Intern",
        "location":"Remote",
        "team":"Software Development"
    },
    {
        "id":4,
        "role":"Software Intern",
        "location":"Pune",
        "team":"Software Development"
    },
    {
        "id":5,
        "role":"Software Intern",
        "location":"Pune",
        "team":"Software Development"
    },
    {
        "id":6,
        "role":"Software Intern",
        "location":"Remote",
        "team":"Software Development"
    },
    {
        "id":7,
        "role":"HR Manager",
        "location":"Pune",
        "team":"Human Resource"
    },
    {
        "id":8,
        "role":"Administrator",
        "location":"Pune",
        "team":"Administration"
    },
    {
        "id":9,
        "role":"Receptionist",
        "location":"Pune",
        "team":"Administration"
    },

]