import React from "react";
import "./Aptitude Frontend/styles/AptitudeApp.css";
import "./index.css";
import "./App.css";

import Navbar from "./landing page module/navbar";
import Services from "./landing page module/Services";
import About from "./contact us module/About";
import Career from "./recruitment module/Career";
import { Routes, Route } from "react-router-dom";
import Home from "./landing page module/Home";
import Contact from "./contact us module/Contact";
import Footer from "./landing page module/Footer";
import Jobs from "./recruitment module/Jobs";
import JobDescription from "./recruitment module/JobDescription";
import NotFound from "./NotFound";
import Main from "./Aptitude Frontend/components/Main";
import Quiz from "./Aptitude Frontend/components/Quiz";
import Result from "./Aptitude Frontend/components/Result";
import { CheckUserExist } from "./Aptitude Frontend/helper/helper";

function App() {
  const isAptitudePage = (pathname) => {
    return (
      pathname.startsWith("/aptitude") ||
      pathname.startsWith("/quiz") ||
      pathname.startsWith("/result")
    );
  };

  return (
    <div className="App">
      {isAptitudePage(window.location.pathname) ? null : <Navbar />}
      {/* <RouterProvider router={router}/> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aptitude" element={<Main />} />
        <Route
          path="/quiz"
          element={
            <CheckUserExist>
              <Quiz />
            </CheckUserExist>
          }
        />
        <Route
          path="/result"
          element={
            <CheckUserExist>
              <Result />
            </CheckUserExist>
          }
        />
        <Route path="/logo" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/jobdescription" element={<JobDescription />} />

        <Route path="*" element={<NotFound />} />
      </Routes>

      {isAptitudePage(window.location.pathname) ? null : <Footer />}
    </div>
  );
}

export default App;
