import React from "react";
import { useState, useRef } from "react";
import Lottie from "lottie-react";
import "./Career.css";
import career from "../assets/career-animation.json";
//import Jobs from "./Jobs";
//import { Link } from "react-router-dom";
//import contactUs from "../assets/contactus.json";
//import axios from "axios";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Career() {
  const form = useRef();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    hiringReason: "",
    attachment: null, //To store the uploaded file
  });
  const [showTick, setShowTick] = useState(false);

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission

    // Reset form fields
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      hiringReason: "",
    });

    // Show tick emoji for 3 seconds
    setShowTick(true);
    setTimeout(() => {
      setShowTick(false);
    }, 3000);
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     cv: file
  //   }));
  // };

  //   const handleSubmit = async(e)=>{
  //     e.preventDefault();
  //     try{
  //         const response = await axios.post('/submit',formData);
  //         console.log(response.data);
  //     }
  //     catch{
  //         console.error(e);
  //     }
  //   }

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     console.log(formData);

  //     // Make an HTTP POST request to your Node.js server
  //     /*axios.post('http://localhost:3000/api/save-user', formData)
  //       .then((response) => {
  //         console.log('User data saved successfully:', response.data);
  //         // Optionally, you can reset the form after successful submission
  //         setFormData({
  //           firstName: '',
  //           lastName: '',
  //           email: '',
  //           phoneNumber: '',
  //           hiringReason: '',
  //         });
  //       })
  //       .catch((error) => {
  //         console.error('Error saving user data:', error);
  //       });*/
  //   };

  const sendEmail = async (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_l8nk43b", "template_4zq7eum", form.current, {
        publicKey: "cxOXHbS1pO_q4wdPK",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.phoneNumber ||
      !formData.hiringReason
    ) {
      toast.error("Please fill in all fields");
    } else {
      // Handle form submission
      console.log("Submitted;", formData);
      //Clear form fields
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        hiringReason: "",
      });
      toast.success("Thank you ! We will contact you soon");
    }
  };

  // let dataSend = {
  //   firstName: formData.firstName,
  //   lastName: formData.lastName,
  //   email: formData.email,
  //   phoneNumber: formData.phoneNumber,
  //   hiringReason: formData.hiringReason,
  // };

  // const res = await fetch(`http://localhost:5000/email/sendEmail`, {
  //   method: "POST",
  //   body: JSON.stringify(dataSend),
  //   headers: {
  //     Accept: "application/json",
  //     "Content-Type": "application/json",
  //   },
  // })
  //   // HANDLING ERRORS
  //   .then((res) => {
  //     console.log(res);
  //     if (res.status > 199 && res.status < 300) {
  //       alert("Send Successfully !");
  //     }
  //   });

  const careerText = "Join Us";
  const careerTextPara =
    "At Incronix, the impact you make will help shape our growth and ability to help teams around the world achieve their missions. You'll be surrounded by a passionate team invested in your growth, providing the resources and guidance to propel you towards your ambitions.";

  return (
    <div className="career-main-div">
      <div className="career-text-div">
        <h1 className="career-text-h1">{careerText}</h1>

        <p className="career-text-para">{careerTextPara}</p>
        <div className="animation">
          <Lottie className="career-animation-lottie" animationData={career} />
        </div>
      </div>
      <div className="career-form-div">
        <form
          ref={form}
          className="career-form"
          action="mailto:hr@incronix.com"
          method="POST"
          encType="text/plain"
          name="CareerForm"
          onSubmit={sendEmail}
        >
          <div className="career-input-div">
            <label className="career-form-labels">First Name</label>{" "}
            <input
              className="career-input-tags"
              name="firstName"
              type="text"
              value={formData.firstName}
              placeholder="Jane"
              onChange={handleInputChange}
              minLength={2}
              required
            ></input>
          </div>

          <div className="career-input-div">
            <label className="career-form-labels">Last Name</label>{" "}
            <input
              className="career-input-tags"
              name="lastName"
              type="text"
              value={formData.lastName}
              placeholder="Moris"
              onChange={handleInputChange}
              required
            ></input>
          </div>

          <div className="career-input-div">
            <label className="career-form-labels">Email</label>{" "}
            <input
              className="career-input-tags"
              name="email"
              type="email"
              value={formData.email}
              placeholder="Jane@abc.com"
              onChange={handleInputChange}
              required
            ></input>
          </div>

          <div className="career-input-div">
            <label className="career-form-labels">Phone number</label>{" "}
            <input
              className="career-input-tags"
              name="phoneNumber"
              type="tel"
              maxLength={15}
              value={formData.phoneNumber}
              onChange={handleInputChange}
              placeholder="+1 555 555 5555"
              required
            ></input>
          </div>

          <div className="career-input-div">
            <label className="career-form-labels">
              Why should we hire you?
              <span className="words-limit">(250 words max.)</span>
            </label>{" "}
            <textarea
              className="career-textarea"
              name="hiringReason"
              rows="40"
              maxLength={250}
              value={formData.hiringReason}
              onChange={handleInputChange}
              placeholder="Tell us about your qualities and skills"
              required
            ></textarea>
          </div>
          <div className="career-form-button">
            <button className="career-submit-button" type="submit" value="send">
              <span>Submit</span>
            </button>
            {showTick && <span className="tick-emoji">✅</span>}
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
}

export default Career;
