import "../styles/AptitudeApp.css";
import "../styles/quiz.css";
// import React, { useState, useEffect } from "react";
// import stopwatch from '../assets/timer.png';

// const Timer = ({ duration, onTimeout }) => {
//   const storedTimer = localStorage.getItem("timer");
//   const initialTimer = storedTimer ? parseInt(storedTimer, 10) : duration;
//   const [timer, setTimer] = useState(initialTimer);

//   useEffect(() => {
//     const countdown = setInterval(() => {
//       setTimer((prevTimer) => {
//         const newTimer = prevTimer - 1;
//         localStorage.setItem("timer", newTimer.toString());

//         if (newTimer <= 0) {
//           clearInterval(countdown);
//           onTimeout();
//         }

//         return newTimer;
//       });
//     }, 1000);

//     return () => {
//       clearInterval(countdown);
//     };
//   }, [onTimeout]);

//   const formatTime = (seconds) => {
//     const hours = Math.floor(seconds / 3600);
//     const minutes = Math.floor((seconds % 3600) / 60);
//     const remainingSeconds = seconds % 60;
  
//     const formattedHours = String(hours).padStart(2, '0');
//     const formattedMinutes = String(minutes).padStart(2, '0');
//     const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  
//     return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
//   };

//   const timerClassName = timer < 60 ? "timer-red" : "timer";

//   return (
//     <div className={timerClassName}>
//       <img src={stopwatch} className="timer-img" alt=''/> <p className="timer-left-para"> Time Left : {formatTime(timer)}</p>
//     </div>
//   );
// };

// export default Timer;
import React, { useState, useEffect } from "react";
import stopwatch from '../assets/timer.png';

const Timer = ({ duration, onTimeout }) => {
  const storedTimer = localStorage.getItem("timer");
  let initialTimer = storedTimer ? parseInt(storedTimer, 10) : duration;

  // Ensure initialTimer is within the valid range [0, duration]
  initialTimer = Math.max(0, Math.min(initialTimer, duration));

  const [timer, setTimer] = useState(initialTimer);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        const newTimer = prevTimer - 1;
        localStorage.setItem("timer", newTimer.toString());

        if (newTimer <= 0) {
          clearInterval(countdown);
          onTimeout();
        }

        return newTimer;
      });
    }, 1000);

    return () => {
      clearInterval(countdown);
    };
  }, [onTimeout]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const timerClassName = timer < 60 ? "timer-red" : "timer";

  return (
    <div className={timerClassName}>
      <img src={stopwatch} className="timer-img" alt=''/> <p className="timer-left-para"> Time Left : {formatTime(timer)}</p>
    </div>
  );
};

export default Timer;
