import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getServerData } from "../helper/helper";

import * as Action from "../redux/question_reducer";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const useFetchQestion = () => {
  const dispatch = useDispatch();
  const [getData, setGetData] = useState({
    isLoading: false,
    apiData: [],
    serverError: null,
  });

  useEffect(() => {
    setGetData((prev) => ({ ...prev, isLoading: true }));

    const fetchData = async () => {
      try {
        const [{ questions, answers }] = await getServerData(
          `${process.env.REACT_APP_SERVER_HOSTNAME}/api/questions`,
          (data) => data
        );

        

        if (questions.length > 0) {
          // Fetch only the first 30 questions
          const limitedQuestions = questions.slice(0, 30);

          // Combine questions and answers into pairs
          const questionAnswerPairs = limitedQuestions.map((question) => ({
            question,
            answer: answers.find((ans) => ans.id === question.id),
          }));

          // Shuffle question-answer pairs
          const shuffledPairs = shuffleArray(questionAnswerPairs);

          // Separate shuffled questions and answers
          const shuffledQuestions = shuffledPairs.map((pair) => pair.question);
          const shuffledAnswers = shuffledPairs.map((pair) => pair.answer);

          setGetData((prev) => ({ ...prev, isLoading: false }));
          setGetData((prev) => ({ ...prev, apiData: shuffledQuestions }));

          dispatch(
            Action.startExamAction({
              question: shuffledQuestions,
              answers: shuffledAnswers,
            })
          );
          
        } else {
          throw new Error("No Question Avalibale");
        }
      } catch (error) {
        setGetData((prev) => ({ ...prev, isLoading: false }));
        setGetData((prev) => ({ ...prev, serverError: error }));
      }
    };
    fetchData();
  }, [dispatch]);

  return [getData, setGetData];
};

export const MoveNextQuestion = () => async (dispatch) => {
  try {
    dispatch(Action.moveNextAction()); /** increase trace by 1 */
  } catch (error) {
    console.log(error);
  }
};

export const MovePrevQuestion = () => async (dispatch) => {
  try {
    dispatch(Action.movePrevAction()); /** decrease trace by 1 */
  } catch (error) {
    console.log(error);
  }
};
