import React, { useEffect } from "react";
import "../styles/Result.css";
import Footer from "../../landing page module/Footer";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  attempts_Number,
  earnPoints_Number,
  flagResult,
} from "../helper/helper";

import { resetAllAction } from "../redux/question_reducer";
import { resetResultAction } from "../redux/result_reducer";

import { usePublishResult } from "../hooks/setResult";

export default function Result() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    questions: { queue, answers },
    result: { result, userId },
  } = useSelector((state) => state);
  console.log(answers);

  const totalPoints = queue.length * 2;
  const attempts = attempts_Number(result);
  const earnPoints = earnPoints_Number(queue, result, answers, 2);
  const flag = flagResult(totalPoints, earnPoints);
  
  // usePublishResult({
  //     result,
  //     username : userId,
  //     attempts,
  //     points: earnPoints,
  //     achived : flag ? "Passed" : "Failed" });

  // Call usePublishResult directly within the functional component body
  usePublishResult({
    result,
    username: userId,
    attempts,
    points: earnPoints,
    achived: flag ? "Passed" : "Failed",
  });

  useEffect(() => {
    // Additional logic if needed when the component mounts or updates
    
  }, [result, answers, userId, attempts, earnPoints, flag]);

  function onRestart() {
    dispatch(resetAllAction());
    dispatch(resetResultAction());
  }
  const goBack = () => {
    navigate("/home");
  };

  return (
    <div className="result-main-div">
      <div className="aknowledgement-box">
        <h1 className="title text-light">
          Your test has been successfully submitted. Further communication
          regarding the process will be sent to your email.
        </h1>
        <button className="go-back" onClick={goBack}>
          Go Back
        </button>
      </div>
      <Footer />
    </div>
  );
}
