import React from "react";
import "./Services.css";
import Card from "./Card";
import { cardContent } from "./cardData";
import laptopPng from "../assets/Laptop.jpg";
import standAlone from "../assets/LimeWire7.webp";
import pda from "../assets/PDA.webp";
import websites from "../assets/LimeWire 2.webp";

function Services() {
  const cardContent = [
    {
      id: 1,
      title: "Standalone Application Mobile App Development:",
      imgUrl: { standAlone },
      subTitle:
        "In the era of mobile-first, we design and develop cutting-edge mobile applications for iOS and Android. Whether it's a consumer-facing app or an enterprise-level solution, we deliver top-notch mobile experiences",
      para1:
        "iOS App Development: Our team of skilled iOS developers is well-versed in creating exceptional apps for Apple's ecosystem. Whether you need a sleek consumer app or a powerful enterprise solution, we design and develop for iOS with precision",
      para2:
        "Android App Development: Android is a diverse platform, and we know how to leverage it to your advantage. We create Android apps that are compatible with a wide range of devices, ensuring your app reaches a broad audience",
      para3:
        "Cross-Platform App Development: Reach both iOS and Android users simultaneously with our cross-platform app development expertise. We use frameworks like React Native and Flutter to save time and resources without compromising on app quality",
    },
    {
      id: 2,
      title: "Web Application Development:",
      imgUrl: { websites },
      subTitle:
        "We specialize in building responsive and feature-rich web applications that enhance your online presence and drive business growth. From e-commerce platforms to content management systems, we've got you covered.",
      para1:
        "Responsive Web Development: In today's mobile-first world, it's crucial to have web applications that look and perform beautifully on all devices. Our responsive web development ensures a seamless user experience, whether on a desktop, tablet, or smartphone.",
      para2:
        "E-Commerce Solutions: We specialize in creating robust e-commerce platforms that empower businesses to sell their products and services online. From secure payment gateways to product management, we have it all covered.",
      para3:
        "Content Management Systems (CMS): Take control of your website's content with our custom CMS solutions. Update, edit, and manage your website's content effortlessly, with no technical expertise required.",
    },
    {
      id: 3,
      title: "PDA:",
      imgUrl: { pda },
      subTitle:
        "At INCRONIX, we offer comprehensive Personal Digital Assistant (PDA) services designed to enhance productivity and streamline daily tasks for individuals and businesses. Our PDA solutions leverage cutting-edge technology to create digital assistants that simplify your life and work.",
      para1:
        " PDA Development and Customization: Our PDA experts are skilled in developing and customizing digital assistants tailored to your unique needs. Whether you require a virtual assistant for managing tasks, schedules, or specific business processes, we've got you covered.",
      para2:
        "Task Automation: We design PDAs that can automate routine tasks, such as email filtering, appointment scheduling, and data entry. This streamlines your daily workflow and frees up your time for more critical activities.",
      para3:
        "Voice Recognition and Natural Language Processing: Our PDAs can be integrated with voice recognition and natural language processing technology, allowing you to interact with your digital assistant through voice commands and natural language, making your tasks even more convenient.",
    },
  ];
  const cardHeadingText = "Here is Something you should know";
  const cardHeadingpara = "Whether you want to start with a pre-built template for marketing, operations, product, learn more from a demo, or talk to our support team, INCRONIX can help with that.";
  return (
    <div className="services-main-div">
      <div className="above-card-text">
        <h2 className="card-heading-text">{cardHeadingText}</h2>
        <p className="card-heading-para">
          {cardHeadingpara}
        </p>
      </div>
      <section className="card-section">
        <Card
          title="Local Application"
          imgUrl={standAlone}
          subTitle={
            "Experience operational efficiency through our bespoke Local Applications, meticulously crafted for your specific needs.Elevate your workflow with our tailored Local Applications, ensuring a seamless and personalized user experience.Enhance your business capabilities with our cutting-edge Local Applications, designed for optimal performance and reliability."
          }
        />
        <Card
          title="Web Service"
          imgUrl={websites}
          subTitle={
            "Navigate the digital realm with our dynamic Web Services, combining innovation and accessibility for a global audience.Empower your online presence with our sophisticated Web Services, engineered to drive success in the ever-evolving digital landscape. Optimize your digital strategy with our Web Services, delivering a seamless, secure, and scalable online experience."
          }
        />
        <Card
          title="Native Apps"
          imgUrl={pda}
          subTitle={
            "Immerse yourself in exceptional user experiences through our Native Apps, meticulously developed to merge functionality with aesthetic sophistication.Unlock the full potential of your mobile device with our Native Apps, finely tuned for optimal performance and user-centric design.Embrace a new standard of mobile excellence with our Native Apps, seamlessly blending innovation and precision at your fingertips."
          }
        />
      </section>
    </div>
  );
}
export default Services;
