import React from 'react';

import './Table.css';
// import { useHistory } from 'react-router-dom';


const Table = ({data}) => {

//     const history = useHistory();

//     const handleClick=(item, history)=>{
//         console.log({ item });
//   // Use the 'history' object to navigate to the Home page and pass props
//         history.push({
//         pathname: '/jobdescription',
//         state: { data: item } // Pass your data as 'state'
//         });
//     }

  return (
    <div>
        <table className='table-main'>
            <tbody className='table-body'>
            <div className='table-shadow'>
                <tr>
                    <th>
                        Role
                    </th>
                    <th>
                        Team
                    </th>
                    <th>
                        Location
                    </th>
                </tr>
                
                {data.map((item)=>(
                    <tr className="table-rows" onClick={()=>{}} key={item.id}>
                        <td>{item.role}</td>
                        <td>{item.team}</td>
                        <td>{item.location}</td>
                    </tr>
                ))}
                </div>
            </tbody>
        </table>
    </div>
  )
}

export default Table;