// Loader.js
import React from "react";
import "../styles/loader.css";

function Loader() {
  <div className="loader-container">
    <div className="loader"></div>
    
  </div>;
}
export default Loader;
