// NotFound.jsx
import React from 'react';
import './NotFound.css'; // Import the CSS file for styling

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="card">
        <div className="not-found-content">
          <h1 className="not-found-title">404</h1>
          <p className="not-found-text">Oops! Page not found</p>
          <p className="not-found-subtext">The page you are looking for might be in another galaxy.</p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
