import React, { useState, useRef } from "react";
import axios from "axios";
import "./Contact.css";
import contactUs from "../assets/contactus.json";
import Lottie from "lottie-react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const form = useRef();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendForm = async (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_l8nk43b", "template_da63elg", form.current, {
        publicKey: "cxOXHbS1pO_q4wdPK",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      !formData.phoneNumber ||
      !formData.message
    ) {
      toast.error("Please fill in all fields");
    } else {
      //Handle form submission
      console.log("Form submitted:", formData);
      //Clear form fields
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        message: "",
      });
      toast.success("Form submitted successfully!");
    }

    // let dataSend = {
    //   firstName: formData.firstName,
    //   lastName: formData.lastName,
    //   email: formData.email,
    //   phoneNumber: formData.phoneNumber,
    //   message: formData.message,
    // };

    // const res = await fetch(`http://localhost:5000/email/sendForm`, {
    //   method: "POST",
    //   body: JSON.stringify(dataSend),
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    // })
    //   // HANDLING ERRORS
    //   .then((res) => {
    //     console.log(res);
    //     if (res.status > 199 && res.status < 300) {
    //       alert("Send Successfully !");
    //     }
    //   });
  };
  const contactText = "Get in Touch";
  const contactTextPara =
    "We’d love to know more about your project. Contact us at hr@incronix.com or simply fill up the form.";
  return (
    <div className="contact-main-div">
      <div className="contact-text-div">
        <h1 className="contact-text-h1">{contactText}</h1>

        <p className="contact-text-para">{contactTextPara}</p>
        <div className="contact-animation">
          <Lottie
            className="contact-animation-lottie"
            animationData={contactUs}
          />
        </div>
      </div>
      <div className="contact-form-div">
        <form
          ref={form}
          className="contact-form"
          action="mailto:hr@incronix.com"
          method="POST"
          encType="text/plain"
          name="ContactForm"
          onSubmit={sendForm}
        >
          <div className="contact-input-div">
            <label className="contact-form-labels">
              <span>First Name</span>
              <input
                className="contact-input-tags"
                type="text"
                name="firstName"
                placeholder="Jane"
                value={formData.firstName}
                onChange={handleChange}
                minLength={2}
                required
              />
            </label>{" "}
          </div>

          <div className="contact-input-div">
            <label className="contact-form-labels">
              Last Name
              <input
                className="contact-input-tags"
                type="text"
                name="lastName"
                placeholder="Moris"
                value={formData.lastName}
                onChange={handleChange}
                required
              ></input>
            </label>{" "}
          </div>

          <div className="contact-input-div">
            <label className="contact-form-labels">
              Email
              <input
                className="contact-input-tags"
                type="email"
                name="email"
                placeholder="Jane@abc.com"
                value={formData.email}
                onChange={handleChange}
                required
              ></input>
            </label>{" "}
          </div>

          <div className="contact-input-div">
            <label className="contact-form-labels">
              Phone number
              <input
                className="contact-input-tags"
                type="tel"
                name="phoneNumber"
                placeholder="+1 555 555 5555"
                value={formData.phoneNumber}
                onChange={handleChange}
                maxLength={15}
                required
              ></input>
            </label>{" "}
          </div>

          <div className="contact-input-div">
            <label className="contact-form-labels">
              What would you like to discuss?
            </label>{" "}
            <textarea
              className="contact-input-tags"
              name="message"
              placeholder="Tell us more about your project, needs and timeline"
              value={formData.message}
              onChange={handleChange}
              rows="40"
              maxLength={500}
              required
            ></textarea>
          </div>

          <div className="contact-form-button">
            <button className="contact-submit-button" type="submit">
              <span>Submit</span>
            </button>
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
}

export default Contact;
