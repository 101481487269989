import React, { useState } from "react";
import "./navbar.css";
import { Link, Outlet } from "react-router-dom";
import logo from "../assets/incronix-03.webp";

function Navbar() {
  // var [fix, setFix]= useState(false);
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");

  // function setFixed(){
  //     if(window.scrollY >=86){
  //       setFix=true;
  //       // console.log(setFix);
  //       // console.log(window.scrollY);
  //     }
  //     else{
  //       setFix=false;
  //       // console.log(setFix);
  //       // console.log(window.scrollY);
  //     }

  // }

  const closeNav = () => {
    setActive("nav__menu");
    setIcon("nav__toggler");
  };

  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };

  // window.addEventListener("scroll",setFixed);

  return (
    <>
      <nav className="nav">
        <Link to="/home" className="nav__brand">
          <img className="nav_logo" src={logo} alt="INCRONIX Logo" loading="lazy"/>
        </Link>
        <ul className={active}>
          <li className="nav__item">
            <Link className="nav__link" to="/home" onClick={closeNav}>
              Home
            </Link>
          </li>
          <li className="nav__item">
            <Link className="nav__link" to="/services" onClick={closeNav}>
              Services
            </Link>
          </li>
          <li className="nav__item">
            <Link className="nav__link" to="/about" onClick={closeNav}>
              About us
            </Link>
          </li>
          <li className="nav__item">
            <Link className="nav__link" to="/career" onClick={closeNav}>
              Career
            </Link>
          </li>
          <li className="nav__item">
            <Link className="nav__link" to="/contact" onClick={closeNav}>
              Contact us
            </Link>
          </li>
        </ul>
        <div onClick={navToggle} className={icon}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </nav>
      <Outlet />
      <main className="flex gap-between"></main>
    </>
  );
}

export default Navbar;
